<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <template v-if="loading">
            <base-loading />
          </template>
          <template v-else>
            <div class="column is-6">
              <div class="card">
                <div class="card-content">
                  <div class="columns is-multiline is-vcentered">
                    <!-- Facebook -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Facebook</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Facebook"
                        v-model="dataFacebook.url"
                        name="facebook"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Facebook"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataFacebook.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatus"
                            type="checkbox"
                            name="switchStatusStatus"
                            class="switch is-rounded is-small"
                            :checked="dataFacebook.active ? 'checked' : ''"
                            v-model="dataFacebook.active"
                          >
                          <label class="text_green_dark" for="switchStatus"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataFacebook.active ? 'text_green' : 'text_error' ]">
                          {{ dataFacebook.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('facebook')">
                      <span v-show="vverrors.has('facebook')" class="help is-danger">{{ vverrors.first('facebook') }}</span>
                    </div>
                    <!-- LinkedIn -->
                    <div class="column is-12">
                      <label class="label text_green_dark">LinkedIn</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="LinkedIn"
                        v-model="dataLinkedin.url"
                        name="LinkedIn"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de LinkedIn"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataLinkedin.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusStatusLI"
                            type="checkbox"
                            name="switchStatusStatusLI"
                            class="switch is-rounded is-small"
                            :checked="dataLinkedin.active ? 'checked' : ''"
                            v-model="dataLinkedin.active"
                          >
                          <label class="text_green_dark" for="switchStatusStatusLI"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataLinkedin.active ? 'text_green' : 'text_error' ]">
                          {{ dataLinkedin.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('LinkedIn')">
                      <span v-show="vverrors.has('LinkedIn')" class="help is-danger">{{ vverrors.first('LinkedIn') }}</span>
                    </div>
                    <!-- YouTube -->
                    <div class="column is-12">
                      <label class="label text_green_dark">YouTube</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="YouTube"
                        v-model="dataYoutube.url"
                        name="youtube"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de YouTube"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataYoutube.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusYT"
                            type="checkbox"
                            name="switchStatusYT"
                            class="switch is-rounded is-small"
                            :checked="dataYoutube.active ? 'checked' : ''"
                            v-model="dataYoutube.active"
                          >
                          <label class="text_green_dark" for="switchStatusYT"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataYoutube.active ? 'text_green' : 'text_error' ]">
                          {{ dataYoutube.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('youtube')">
                      <span v-show="vverrors.has('youtube')" class="help is-danger">{{ vverrors.first('youtube') }}</span>
                    </div>
                    <!-- Twitter -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Twitter</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Twitter"
                        v-model="dataTwitter.url"
                        name="twitter"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Twitter"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataTwitter.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusTW"
                            type="checkbox"
                            name="switchStatusTW"
                            class="switch is-rounded is-small"
                            :checked="dataTwitter.active ? 'checked' : ''"
                            v-model="dataTwitter.active"
                          >
                          <label class="text_green_dark" for="switchStatusTW"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataTwitter.active ? 'text_green' : 'text_error' ]">
                          {{ dataTwitter.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('twitter')">
                      <span v-show="vverrors.has('twitter')" class="help is-danger">{{ vverrors.first('twitter') }}</span>
                    </div>
                    <!-- Medium -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Medium</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Medium"
                        v-model="dataMedium.url"
                        name="medium"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Medium"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataMedium.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusMD"
                            type="checkbox"
                            name="switchStatusMD"
                            class="switch is-rounded is-small"
                            :checked="dataMedium.active ? 'checked' : ''"
                            v-model="dataMedium.active"
                          >
                          <label class="text_green_dark" for="switchStatusMD"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataMedium.active ? 'text_green' : 'text_error' ]">
                          {{ dataMedium.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('medium')">
                      <span v-show="vverrors.has('medium')" class="help is-danger">{{ vverrors.first('medium') }}</span>
                    </div>
                    <!-- Instagram -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Instagram</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Instagram"
                        v-model="dataInstagram.url"
                        name="instagram"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Instagram"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataInstagram.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusIG"
                            type="checkbox"
                            name="switchStatusIG"
                            class="switch is-rounded is-small"
                            :checked="dataInstagram.active ? 'checked' : ''"
                            v-model="dataInstagram.active"
                          >
                          <label class="text_green_dark" for="switchStatusIG"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataInstagram.active ? 'text_green' : 'text_error' ]">
                          {{ dataInstagram.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('instagram')">
                      <span v-show="vverrors.has('instagram')" class="help is-danger">{{ vverrors.first('instagram') }}</span>
                    </div>
                    <!-- Clubhouse -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Clubhouse</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Clubhouse"
                        v-model="dataClubhouse.url"
                        name="clubhouse"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Clubhouse"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataClubhouse.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusCH"
                            type="checkbox"
                            name="switchStatusCH"
                            class="switch is-rounded is-small"
                            :checked="dataClubhouse.active ? 'checked' : ''"
                            v-model="dataClubhouse.active"
                          >
                          <label class="text_green_dark" for="switchStatusCH"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataClubhouse.active ? 'text_green' : 'text_error' ]">
                          {{ dataClubhouse.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('clubhouse')">
                      <span v-show="vverrors.has('clubhouse')" class="help is-danger">{{ vverrors.first('clubhouse') }}</span>
                    </div>
                    <!-- Goodreads -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Goodreads</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Goodreads"
                        v-model="dataGoodreads.url"
                        name="goodreads"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Goodreads"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataGoodreads.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusGR"
                            type="checkbox"
                            name="switchStatusGR"
                            class="switch is-rounded is-small"
                            :checked="dataGoodreads.active ? 'checked' : ''"
                            v-model="dataGoodreads.active"
                          >
                          <label class="text_green_dark" for="switchStatusGR"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataGoodreads.active ? 'text_green' : 'text_error' ]">
                          {{ dataGoodreads.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <!-- Tiktok -->
                    <div class="column is-12">
                      <label class="label text_green_dark">Tiktok</label>
                    </div>
                    <div class="column is-6">
                      <input
                        v-if="modeEdit"
                        type="text"
                        class="input"
                        placeholder="Tiktok"
                        v-model="dataTiktok.url"
                        name="tiktok"
                        v-validate="{ required: true, url: { require_protocol: true }}"
                        data-vv-as="de la url de Tiktok"
                      >
                      <p v-else class="text_green_dark text_16">
                        {{ dataTiktok.url }}
                      </p>
                    </div>
                    <div class="column is-5 is-offset-1 is-flex is-flex-direction-row is-align-items-center">
                      <label class="text_green_dark text_16 mr-5">Mostrar</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchStatusTT"
                            type="checkbox"
                            name="switchStatusTT"
                            class="switch is-rounded is-small"
                            :checked="dataTiktok.active ? 'checked' : ''"
                            v-model="dataTiktok.active"
                          >
                          <label class="text_green_dark" for="switchStatusTT"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="text_16" :class="[ dataTiktok.active ? 'text_green' : 'text_error' ]">
                          {{ dataTiktok.active ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <div class="column is-12 py-0" v-show="vverrors.has('tiktok')">
                      <span v-show="vverrors.has('tiktok')" class="help is-danger">{{ vverrors.first('tiktok') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialMediaMain',
  components: {
    BaseLoading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      dataFacebook: {
        url: null,
        active: false
      },
      dataLinkedin: {
        url: null,
        active: false
      },
      dataYoutube: {
        url: null,
        active: false
      },
      dataTwitter: {
        url: null,
        active: false
      },
      dataMedium: {
        url: null,
        active: false
      },
      dataInstagram: {
        url: null,
        active: false
      },
      dataClubhouse: {
        url: null,
        active: false
      },
      dataGoodreads: {
        url: null,
        active: false
      },
      dataTiktok: {
        url: null,
        active: false
      }
    }
  },
  props: {
    modeEdit: {
      type: Boolean,
      default: false
    },
    socialMediaData: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async getLocalData () {
      if (await this.$validator.validateAll()) {
        const dataUpdate = [
          {
            id: this.socialMediaData.facebook.id,
            url: this.dataFacebook.url,
            active: this.dataFacebook.active
          },
          {
            id: this.socialMediaData.linkedin.id,
            url: this.dataLinkedin.url,
            active: this.dataLinkedin.active
          },
          {
            id: this.socialMediaData.youtube.id,
            url: this.dataYoutube.url,
            active: this.dataYoutube.active
          },
          {
            id: this.socialMediaData.twitter.id,
            url: this.dataTwitter.url,
            active: this.dataTwitter.active
          },
          {
            id: this.socialMediaData.medium.id,
            url: this.dataMedium.url,
            active: this.dataMedium.active
          },
          {
            id: this.socialMediaData.instagram.id,
            url: this.dataInstagram.url,
            active: this.dataInstagram.active
          },
          {
            id: this.socialMediaData.clubhouse.id,
            url: this.dataClubhouse.url,
            active: this.dataClubhouse.active
          },
          {
            id: this.socialMediaData.goodreads.id,
            url: this.dataGoodreads.url,
            active: this.dataGoodreads.active
          },
          {
            id: this.socialMediaData.tiktok.id,
            url: this.dataTiktok.url,
            active: this.dataTiktok.active
          }
        ]
        this.$emit('set-data-update', dataUpdate)
      }
    }
  },
  beforeMount () {
    this.dataFacebook = this.socialMediaData.facebook
    this.dataLinkedin = this.socialMediaData.linkedin
    this.dataYoutube = this.socialMediaData.youtube
    this.dataTwitter = this.socialMediaData.twitter
    this.dataMedium = this.socialMediaData.medium
    this.dataInstagram = this.socialMediaData.instagram
    this.dataClubhouse = this.socialMediaData.clubhouse
    this.dataGoodreads = this.socialMediaData.goodreads
    this.dataTiktok = this.socialMediaData.tiktok
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .switch[type="checkbox"].is-small + label {
    font-size: 16px;
    padding-top: 0;
  }
</style>
